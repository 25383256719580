body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,a:visited {
    text-decoration: none;
    color: inherit;
}

li:lang(ar){
    font-family: 'Cairo', sans-serif;
}

.app {
    font-family: 'Roboto', sans-serif;
}

.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    color: white;
}

.effect {
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
    position: absolute;
}

.welcome .content {
    padding: 0 15px;
    margin: auto;
    z-index: 1;
}

.welcome .content h1 {
    font-weight: 400;
    font-size: 28px;
}


.footer {
    align-self: flex-end;
    position: relative;
    margin: .8em auto;
    z-index: 1;
    cursor: pointer;
    height: 70px;
    margin-bottom: 20px;
}

.github
{
    -webkit-transition: all 1s;
    transition: all 1s;
}

#works .card-content {
    display: flex;
}

.work {
    flex: 50% 1;
}

.work h2 {
    text-align: center;
}

.contact {
    background-color: #A93226;
    color: white;
    font-weight: 100;
    font-size: 20px;
}

.contact div{
    padding: 15px;
    text-align: center;
}

@media screen and (min-width:1101px){
    .lang div:hover {
        cursor: pointer;
        background-color: #186A3B;
    }
}

@media screen and (max-width:1100px){
    #info {
        grid-template-columns: 1fr 1fr;
        width: 70%;
    }

    #works {
        grid-column: 2 span;
    }

    #works .card-content {
        flex-direction: column;
    }

    .lang {
        grid-column: 1/2;
    }
}

@media screen and (max-width:699px) {
    #info {
        grid-template-columns: 1fr;
        width: 90%;
    }

    #works {
        grid-column: 1;
    }
}
